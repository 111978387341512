import { render, staticRenderFns } from "./Mm1Footer.vue?vue&type=template&id=6e422c48&scoped=true&"
import script from "./Mm1Footer.vue?vue&type=script&lang=ts&"
export * from "./Mm1Footer.vue?vue&type=script&lang=ts&"
import style0 from "./Mm1Footer.vue?vue&type=style&index=0&id=6e422c48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e422c48",
  null
  
)

export default component.exports