import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34f4cd42 = () => interopDefault(import('..\\pages\\access-your-card.vue' /* webpackChunkName: "pages_access-your-card" */))
const _6f698664 = () => interopDefault(import('..\\pages\\card-print.vue' /* webpackChunkName: "pages_card-print" */))
const _013aff6a = () => interopDefault(import('..\\pages\\program-requirements\\index.vue' /* webpackChunkName: "pages_program-requirements_index" */))
const _3fa56f00 = () => interopDefault(import('..\\pages\\rebate-form.vue' /* webpackChunkName: "pages_rebate-form" */))
const _5b4faf62 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _34dc068d = () => interopDefault(import('..\\pages\\view-your-account.vue' /* webpackChunkName: "pages_view-your-account" */))
const _59b0390a = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _59940a08 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _595bac04 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _593f7d02 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _080e1ebc = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _9393ea6c = () => interopDefault(import('..\\pages\\program-requirements\\mm1.vue' /* webpackChunkName: "pages_program-requirements_mm1" */))
const _a493679a = () => interopDefault(import('..\\pages\\program-requirements\\program-requirement.vue' /* webpackChunkName: "pages_program-requirements_program-requirement" */))
const _57c7bd98 = () => interopDefault(import('..\\pages\\program-requirements\\rybrevant.vue' /* webpackChunkName: "pages_program-requirements_rybrevant" */))
const _68a86979 = () => interopDefault(import('..\\pages\\program-requirements\\stelara-standard.vue' /* webpackChunkName: "pages_program-requirements_stelara-standard" */))
const _5045ba24 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-your-card",
    component: _34f4cd42,
    name: "access-your-card"
  }, {
    path: "/card-print",
    component: _6f698664,
    name: "card-print"
  }, {
    path: "/program-requirements",
    component: _013aff6a,
    name: "program-requirements"
  }, {
    path: "/rebate-form",
    component: _3fa56f00,
    name: "rebate-form"
  }, {
    path: "/unlock",
    component: _5b4faf62,
    name: "unlock"
  }, {
    path: "/view-your-account",
    component: _34dc068d,
    name: "view-your-account"
  }, {
    path: "/errors/400",
    component: _59b0390a,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _59940a08,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _595bac04,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _593f7d02,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _080e1ebc,
    name: "errors-500"
  }, {
    path: "/program-requirements/mm1",
    component: _9393ea6c,
    name: "program-requirements-mm1"
  }, {
    path: "/program-requirements/program-requirement",
    component: _a493679a,
    name: "program-requirements-program-requirement"
  }, {
    path: "/program-requirements/rybrevant",
    component: _57c7bd98,
    name: "program-requirements-rybrevant"
  }, {
    path: "/program-requirements/stelara-standard",
    component: _68a86979,
    name: "program-requirements-stelara-standard"
  }, {
    path: "/",
    component: _5045ba24,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
